
























































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";

export default Vue.extend({
  data() {
    return {
      dialog: false,
      form: false,
      dialogDelete: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers: [
        { text: "ID", value: "id" },
        { text: this.$t("NAME"), value: "name" },
        { text: this.$t("LOGIN_NAME"), value: "login_name" },
        { text: this.$t("ROLES"), value: "roles", sortable: false },
        { text: this.$t("CODES"), value: "codes", sortable: false },
        { text: this.$t("ACTIONS"), value: "actions", sortable: false },
      ],
      options: {},
      loading: false,
      data: [],
      dataCount: 0,
      editedIndex: -1,
      editedItem: {
        name: "",
        login_name: "",
        password: "",
        roles: [],
        codes: [],
      },
      defaultItem: {
        name: "",
        login_name: "",
        password: "",
        roles: [],
        codes: [],
      },
      availableRoleMap: {
        k: this.$t("ROLE_K"),
        xnk: this.$t("ROLE_XNK"),
        kd: this.$t("ROLE_KD"),
        t: this.$t("ROLE_T"),
        kt: this.$t("ROLE_KT"),
        td: this.$t("ROLE_TD"),
        gd: this.$t("ROLE_GD"),
        cs: "Chính sách",
        htkd: this.$t("ROLE_HTKD"),
        kvn: "Kho VN",
        ktt: "Kế toán trưởng",
        mkt: "Marketing",
        tpkd: "Trưởng phòng kinh doanh",
        gdkd: "Giám đốc kinh doanh",
      },
      tpkds: [],
      gdkds: [],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("CREATE_USER") : this.$t("EDIT");
    },
    availableRoles() {
      const items = [];
      for (const k in this.availableRoleMap) {
        items.push({
          text: this.availableRoleMap[k],
          value: k,
        });
      }
      return items;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  async created() {
    {
      const { items } = await apiClient.userGetAll({
        rawOptions: {
          limit: -1,
        },
        filters: [
          {
            key: "roles",
            operator: "match",
            value: "tpkd",
          },
        ],
      });
      this.tpkds = items;
    }
    {
      const { items } = await apiClient.userGetAll({
        rawOptions: {
          limit: -1,
        },
        filters: [
          {
            key: "roles",
            operator: "match",
            value: "gdkd",
          },
        ],
      });
      this.gdkds = items;
    }
  },
  methods: {
    async initialize() {
      this.loading = true;
      const { items, count } = await apiClient.userGetAll({
        options: this.options,
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.userDelete(this.editedItem);
      await this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let result;
      if (this.editedIndex > -1) {
        result = await apiClient.userUpdate(this.editedItem);
      } else {
        result = await apiClient.userCreate(this.editedItem);
      }
      if (result) {
        this.close();
        await this.initialize();
      }
    },
  },
});
